const Privacy = () => {
    return (
        <>
            <div className="container px-4 md:px-52 w-full">
                <h1 className="text-3xl font-semibold mt-[100px] text-center font-jost">Privacy Policy</h1>
                <h1 className="text-xl font-semibold mt-[100px] font-jost">Last updated  (Nov 1st, 2024)</h1>
                <p className="mt-10 font-poppins">Getbetterbills.com, Inc. (“we,” “us,” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, https://www.getbetterbills.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”). Please read this Privacy Policy carefully. <span className="text-lg font-semibold "> IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE SITE.</span>
                    We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted.
                </p>

                <h1 className="text-xl font-semibold mt-[60px] font-jost">COLLECTION OF YOUR INFORMATION</h1>
                <p className="mt-10 font-poppins">We may collect information about you in a variety of ways. The information we may collect on the Site includes:
                </p>

                <h1 className="text-lg font-semibold mt-[20px] font-jost">Personal Data</h1>
                <p className="mt-1 font-poppins">
                    Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards. You are under no obligation to provide us with personal information of any kind; however, your refusal to do so may prevent you from using certain features of the Site.</p>

                <h1 className="text-lg font-semibold mt-[20px] font-jost">Derivative Data</h1>
                <p className="mt-1 font-poppins">
                    Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.
                </p>

                <h1 className="text-lg font-semibold mt-[20px] font-jost">Financial Data</h1>
                <p className="mt-1 font-poppins">
                    Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, and you are encouraged to review their privacy policies and contact them directly for responses to your questions.
                </p>

                <h1 className="text-xl font-semibold mt-[60px] font-jost">USE OF YOUR INFORMATION</h1>
                <p className="mt-1 font-poppins">Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:</p>

                {/* List with circles */}
                <ul className="list-none mt-4 !poppins pl-5">
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Compile anonymous statistical data and analysis for use internally or with third parties.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Create and manage your account.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Deliver targeted advertising, coupons, newsletters, and promotions, and other information regarding our website to you.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Email you regarding your account or order.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Fulfill and manage purchases, orders, payments, and other transactions related to the Site.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Monitor and analyze usage and trends to improve your experience with the Site.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Notify you of updates to the Site.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Offer new products, services, and/or recommendations to you.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Respond to product and customer service requests.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Assist law enforcement and respond to subpoenas.
                    </li>
                </ul>
                <h1 className="text-xl font-semibold mt-[60px] font-jost">DISCLOSURE OF YOUR INFORMATION</h1>
                <ul className="list-none mt-4 !poppins pl-5">
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        We may share your personal information in certain situations such as:
                    </li>
                    <li className="relative font-semibold  pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        By Law or to Protect Rights
                    </li>
                    <li className="relative mb-2 pl-6">
                        {/* <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span> */}
                        If we believe the release of information about you is necessary to respond to legal processes, investigate violations of our policies, or protect rights, property, and safety, we may share your information as required by law.
                    </li>
                    <li className="relative font-semibold  pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Sale or Bankruptcy
                    </li>
                    <li className="relative mb-2 pl-6">
                        {/* <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span> */}
                        If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, your information may be transferred to the successor entity.</li>
                </ul>
                <h1 className="text-xl font-semibold mt-[60px] font-jost">TRACKING TECHNOLOGIES</h1>
                <p className="mt-2 font-poppins">We may use cookies, tracking pixels, and other technologies on the Site to improve your experience. By using the Site, you agree to our Cookie Policy.
                </p>
                <h1 className="text-xl font-semibold mt-[30px] font-jost">THIRD-PARTY WEBSITES</h1>
                <p className="mt-2 font-poppins">The Site may contain links to third-party websites. We are not responsible for the privacy practices of any third parties.
                </p>
                <h1 className="text-xl font-semibold mt-[30px] font-jost">SECURITY OF YOUR INFORMATION</h1>
                <p className="mt-2 font-poppins">We use administrative, technical, and physical security measures to protect your personal information. However, no security measure is completely secure.  </p>
                <h1 className="text-xl font-semibold mt-[30px] font-jost">POLICY FOR CHILDREN</h1>
                <p className="mt-2 font-poppins">We do not knowingly solicit information from or market to children under the age of 18. If you become aware of any data we have collected from individuals under the age of 18, please contact us using the contact information provided below, and we will take reasonable steps to promptly delete such data from our records. </p>
                <h1 className="text-xl font-semibold mt-[60px] font-jost">OPTIONS REGARDING YOUR INFORMATION</h1>
                <p className="mt-1 font-poppins">You may review or change your account information by:</p>

                {/* List with circles */}
                <ul className="list-none mt-4 !poppins pl-5">
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Logging into your account and updating settings.
                    </li>
                    <li className="relative mb-2 pl-6">
                        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-2.5 h-2.5 bg-black rounded-full"></span>
                        Contacting us at the email below.
                    </li>
                </ul>

                <h1 className="text-2xl font-semibold mt-[30px] font-jost">Contact us</h1>
                <p className="mt-5 font-poppins">
                    For any inquiries or complaints regarding the Site, please contact us at:
                </p>
                <h1 className="text-lg font-semibold mt-[10px] font-jost">Getbetterbills.com, Inc.</h1>
                <p className=" font-poppins">1225 Franklin Ave., Ste. 325 <br />
                    Garden City, NY 11530 <br />
                    United States <br />
                    Email: compliance@getbetterbills.com</p>

            </div>
        </>
    );
};

export default Privacy;
