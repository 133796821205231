const TNC = () => {
    return (<>
        <div className="container  px-4 md:px-52 w-full">
        <h1 className="text-3xl font-semibold mt-[100px] text-center font-jost">TERMS AND CONDITIONS</h1>

            <h1 className="text-xl font-semibold mt-[100px] font-jost">AGREEMENT TO TERMS</h1>
            <p className="mt-10 font-poppins">These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Getbetterbills.com, Inc. (“we,” “us” or “our”), concerning your access to and use of the getbetterbills.com website, any associated applications, as well as any other media form, media channel, mobile website, or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>
            <h1 className="text-xl font-semibold mt-[60px] font-jost">PRIVACY POLICY</h1>
            <p className="mt-10 font-poppins">We care about data privacy and security. Please review our Privacy Policy (linked here: [Insert Link]). By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use. Please be advised that the Site is hosted in the United States.
                If you access the Site from the European Union, Asia, or any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, through your continued use of the Site, you are transferring your data to the United States, and you expressly consent to have your data transferred to and processed in the United States.
                We do not knowingly accept, request, or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information as quickly as is reasonably practical.
            </p>
            <h1 className="text-xl font-semibold mt-[60px] font-jost">PRIVACY POLICY</h1>
            <p className="mt-10 font-poppins">TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE ONE (1) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
            </p>
            <h1 className="text-xl font-semibold mt-[60px] font-jost">INDEMNIFICATION</h1>
            <p className="mt-10 font-poppins">You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: <br /> <br />
                1. Your use of the Site; <br />
                2. Breach of these Terms of Use; <br />
                3.Any breach of your representations and warranties set forth in these Terms of Use; <br />
                4.Your violation of the rights of a third party, including but not limited to intellectual property rights;  <br />
                5.Any overt harmful act toward any other user of the Site with whom you connected via the Site. <br /> <br />
                We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding subject to this indemnification upon becoming aware of it.
            </p>
            <h1 className="text-xl font-semibold mt-[60px] font-jost">DISPUTE RESOLUTION
            </h1>
            <h1 className="text-lg font-semibold mt-[40px] font-jost">Informal Negotiations
            </h1>
            <p className="mt-5 font-poppins">
                To expedite resolution and control the cost of any dispute, controversy, or claim related to these Terms of Use (each a “Dispute” and collectively, the “Disputes”) brought by either you or us (individually, a “Party” and collectively, the “Parties”), the Parties agree to first attempt to negotiate any Dispute informally for at least fifteen (15) days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.
            </p>
            <h1 className="text-lg font-semibold mt-[50px] font-jost">Binding Arbitration
            </h1>
            <p className="mt-5 font-poppins">
                If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute will be resolved by binding arbitration. The arbitration shall be conducted under the laws of the State of New York and administered by the American Arbitration Association (“AAA”) in accordance with its Commercial Arbitration Rules.The arbitration will take place in New York State, and the decision rendered by the arbitrator shall be binding on both Parties.
                In no event shall any Dispute brought by either Party related in any way to the Site be commenced more than one (1) year after the cause of action arose.
            </p>
            <h1 className="text-2xl font-semibold mt-[60px] font-jost">Privacy Policy</h1>
            <p className="mt-5 font-poppins">
            We care about data privacy and security. Please review our Privacy Policy (linked here: https://getbetterbills.com/privacy). By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use. Please be advised that the Site is hosted in the United States.
If you access the Site from the European Union, Asia, or any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, through your continued use of the Site, you are transferring your data to the United States, and you expressly consent to have your data transferred to and processed in the United States.
We do not knowingly accept, request, or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information as quickly as is reasonably practical.
 </p>
 <h1 className="text-2xl font-semibold mt-[60px] font-jost">Contact us</h1>
 <p className="mt-5 font-poppins">
 For any inquiries or complaints regarding the Site, please contact us at:
      </p>
      <h1 className="text-lg font-semibold mt-[10px] font-jost">Getbetterbills.com, Inc.</h1>
      <p className=" font-poppins">1225 Franklin Ave., Ste. 325 <br />
Garden City, NY 11530 <br />
United States <br />
Email: compliance@getbetterbills.com</p>
 
        </div>
    </>)
}
export default TNC