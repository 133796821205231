import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { CONTACT_US_CARD_DATA } from "../utils/Helper";
import Icons from "./common/Icons";
import PrimaryHeading from "./common/PrimaryHeading";
import contactUs from "../assets/images/webp/contact-us.webp";
import circle from "../assets/images/png/hero-circle.png";
import circleBlueDot from "../assets/images/png/blue-dot-circle.png";
// Import Swiper modules from 'swiper/modules'
import { Autoplay, Pagination } from "swiper/modules";

const ContactUs = () => {
  return (
    <div className="pt-[75px] md:pt-[173px] relative" id="contact-us">
      <img
        className="animate-spin-slow xl:size-[161px] md:size-[80px] size-[70px] absolute lg:left-10 sm:left-0 md:top-[130px] -left-8 top-9 pointer-events-none md:block hidden"
        src={circle}
        alt="circle"
      />
      <img
        className="animate-spin-slow xl:size-[161px] md:size-[80px] size-[70px] absolute right-6 lg:bottom-[100px] -bottom-24 pointer-events-none md:block hidden"
        src={circleBlueDot}
        alt="circle"
      />
      <div className="lg:size-[204px] size-[100px] bg-tron-blue lg:blur-[200px] blur-[100px] rounded-full absolute -left-28 -z-10 -bottom-24 pointer-events-none md:block hidden"></div>
      <div className="max-w-[970px] sm:px-5 mx-auto">
        <PrimaryHeading
          className="text-center [text-shadow:0px_0px_7.2px_#00000030]"
          Children="Contact Us"
        />
        <p className="text-center pt-3 text-base font-poppins font-normal leading-lh_150 text-cyan-blue max-sm:px-9">
          Have questions or want to get started? We're here to help!
        </p>
        <div className="lg:pt-[88px] md:pt-[72px] sm:pt-16 pt-[49px]">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]} // Include Pagination and Navigation modules
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 36,
              },
            }}
            className="!pb-10"
          >
            {CONTACT_US_CARD_DATA.map((obj, index) => (
              <SwiperSlide key={index} className="max-sm:px-5">
                <div className="border border-cyan-light-blue hover:bg-navy-blue transition-all duration-300 ease-in-out group shadow-contactUsCard flex-col rounded-br_10 lg:max-w-[286px] max-w-[334px] sm:max-w-[unset] mx-auto w-full h-[265px] flex justify-start items-center px-[10px] pt-4 pb-6">
                  <div className="min-w-[90px] min-h-[90px] rounded-full bg-tron-blue-200 group-hover:bg-tron-blue transition-all duration-300 ease-in-out flex justify-center items-center">
                    <Icons icon={obj.icon} />
                  </div>
                  <p className="pt-[14px] font-inter pb-5 font-semibold text-2xl group-hover:text-white transition-all duration-300 ease-in-out leading-lh_122 text-cyan-blue">
                    {obj.title}
                  </p>
                  {obj.description.map((description, index) => (
                    <a
                      aria-label="contact-links"
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        obj.icon === "chatBot"
                          ? `${obj.url}`
                          : obj.icon === "phone"
                          ? `tel:${description}`
                          : obj.icon === "email"
                          ? `mailto:${description}`
                          : "/"
                      }
                      className={`pt-1 max-w-[267px] mx-auto text-center font-poppins group-hover:text-white transition-all duration-300 cursor-pointer ease-in-out font-normal text-base text-cyan-blue opacity-70 ${index == 0 ? "max-w-[251px]":""}`}
                    >
                      {description}
                    </a>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="sm:block hidden relative z-10 lg:-mt-1 sm:mt-6">
          <img
            src={contactUs}
            alt="contact-us-image"
            className="w-full h-full max-h-[291px] object-cover rounded-br_10"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
