// src/components/Footer.jsx
import React from "react";
import footerLogo from "../assets/images/svg/page-logo.svg";
import rightVector from "../assets/images/png/footer-right-vector.png";
import leftVector from "../assets/images/png/footer-left-vector.png";
import Icons from "./common/Icons";
import { Router,Link } from 'react-router-dom';  // Just import Link for navigation

const Footer = () => {
  return (
    <div className="max-w-[1375px] px-5 mx-auto pt-[75px] md:pt-[173px]">
      <div className="bg-mint-cream relative rounded-tr-br_20 rounded-tl-br_20">
        <div className="md:py-[75px] sm:py-14 pt-11 pb-10 flex justify-center flex-col items-center">
          <img
            src={footerLogo}
            alt="footer-logo"
            className="md:w-[282px] cursor-pointer sm:w-[247px] w-[212px] md:h-[72px] sm:h-[63px] h-[54px] object-cover"
          />
          <p className="lg:pt-11 md:pt-9 lg:px-0 px-2 sm:pt-7 pt-[22px] text-center max-w-[455px] text-cyan-blue font-poppins opacity-70 font-normal leading-lh_150">
            GetBetterBills: 100+ Years of Insider Expertise Navigating Major Carriers to Unlock the Best Unlimited Deals!
          </p>
          <div className="flex justify-center items-center gap-4 md:pt-11 sm:pt-12 pt-[53px]">
            <a aria-label="facebook"
              href="https://www.facebook.com/profile.php?id=61567081846218"
              className="hover:scale-110 transition-all duration-300 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons icon="facebook" />
            </a>
            <a aria-label="instagram"
              href="https://www.instagram.com/getbetterbills?igsh=MWd0eTRtb3l5NGw2bQ=="
              className="hover:scale-110 transition-all duration-300 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons icon="instagram" />
            </a>
            <a aria-label="linkedin"
              href="https://www.linkedin.com/company/105026004/"
              className="hover:scale-110 transition-all duration-300 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons icon="linkedin" />
            </a>
          </div>
   <div className="flex gap-4 items-center">
   <Link to="/tnc" className="text-center text-cyan-blue font-poppins mt-6 block">
            Terms and Conditions
          </Link>
          <Link to="/privacy" className="text-center text-cyan-blue font-poppins mt-6 block">
            Privacy Policy
          </Link>
   </div>
        </div>
        <div className="w-full h-[1px] bg-bluish-cyan"></div>
        <div className="flex justify-center py-4">
          <p className="font-poppins lg:px-0 px-2 max-w-[246px] sm:max-w-[unset] mx-auto font-normal text-sm leading-lh_150 text-center text-cyan-blue opacity-70">
            Copyright © {new Date().getFullYear()} getbetterbills, All Rights Reserved.
          </p>
        </div>
        <img
          src={rightVector}
          alt="footer-right-vector"
          className="w-[130px] h-[305px] absolute top-14 right-0 md:block hidden"
        />
        <img
          src={leftVector}
          alt="footer-left-vector"
          className="w-[130px] h-[305px] absolute top-14 left-0 md:block hidden"
        />
      </div>
    </div>
  );
};

export default Footer;
