import React, { useState } from "react";
import PrimaryHeading from "./common/PrimaryHeading";
import { BENEFITS_CARD_DATA } from "../utils/Helper";
import circle from "../assets/images/png/hero-circle.png";

const Benefit = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => setIsModalOpen(true);

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);
  return (
    <div className="relative pt-[75px] md:pt-[173px] pb-[75px] md:pb-[173px]">
      <img
        className="animate-spin-slow xl:size-[161px] md:size-[80px] size-[70px] absolute lg:left-10 sm:left-0 md:top-[150px] -left-8 top-9 pointer-events-none md:block hidden"
        src={circle}
        alt="circle"
      />
      <div className="max-w-[1180px] px-5 mx-auto" id="benefit">
        <PrimaryHeading
          className="text-center [text-shadow:0px_0px_7.2px_#00000030]"
          Children="How We Benefit You"
        />
        <p className="pt-3 font-inter font-bold text-2xl leading-lh_120 text-center text-cyan-blue">
          Save Up to 70% on Your Monthly Bill
        </p>
        <p className="pt-[11px] font-poppins font-normal text-base leading-lh_150 text-cyan-blue max-w-[526px] mx-auto text-center sm:px-0 px-4">
          We identify areas where you can save, such as removing unused services
          or switching to a better plan.
        </p>
        <div className="md:pt-[84px] pt-[60px] flex md:flex-row flex-col md:gap-[14px] gap-8">
          {BENEFITS_CARD_DATA.map((obj, index) => (
            <div
              key={index}
              className="w-full max-w-[376px] md:max-w-[unset] mx-auto group md:h-[453px] h-[380px] p-[5px] sm:p-2.5 border border-ghost sm:rounded-br_20 rounded-br_10"
            >
              <div className="overflow-hidden rounded-br_10">
                <img
                  src={obj.image}
                  alt="benefit-image"
                  className="w-full md:h-[254px] group-hover:scale-125 transition-all duration-300 ease-in-out max-h-[170px] md:max-h-[unset] object-cover rounded-br_10"
                />
              </div>
              <p className="pt-[38px] text-center text-2xl font-bold sm:font-inter font-jost text-cyan-blue leading-lh_120">
                {obj.title}
              </p>
              <p
                className={`pt-[9px] ${
                  index === 0 ? "max-w-[304px] max-sm:px-5" : "max-w-[490px] max-sm:px-10"
                } mx-auto text-center font-poppins font-normal text-base leading-lh_150 text-cyan-blue opacity-70`}
              >
                {obj.description}
              </p>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <button 
            onClick={openModal} 
            aria-label="get-start"
            className="mt-8 font-semibold w-full max-w-[375px] sm:max-w-[165px] text-base leading-lh_145 font-jost text-navy-blue bg-tron-blue rounded-full px-5 py-[13.6px] border border-transparent border-solid hover:border-cyan-blue hover:bg-transparent transition-all duration-300 text-center"
          >
            Get Started Now
          </button>
        </div>
      </div>
      
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100]">
          <div className="bg-white  relative rounded-lg sm:h-auto h-full w-[100%] sm:w-[700px]">
            <button
              onClick={closeModal}
              className="absolute top-[2px] sm:top-5  sm:pb-1  z-[101] bg-white  rounded-full text-3xl px-2.5 left-[20px] sm:left-5  font-bold text-black"
            >
              &times;
            </button>
            <iframe
              src="https://form.jotform.com/242966086554165"
              className="w-full h-full sm:h-[600px] border-none"
              title="Get Started Form"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Benefit;
