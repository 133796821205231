import React from 'react'; // Import React to avoid the 'React is not defined' error
import "./App.css";
import Benefit from "./components/Benefit";
import ChooseUs from "./components/ChooseUs";
import BackToTop from "./components/common/BackToTop";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import GigWorker from "./components/GigWorker";
import Header from "./components/Header";
import TNC from "./components/term-n-conditionn/TermsandCondition";
import Privacy from './components/term-n-conditionn/PrivacyPolicy';
import Hero from "./components/Hero";
import { MakeSwitch } from "./components/MakeSwitch";
import WhatsAppButton from "./components/wabutton";
import OurClients from "./components/OurClients";
import OurService from "./components/OurService";
import { SwitchCalculate } from "./components/SwitchCalculate";
import YourTrun from "./components/YourTrun";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to the top of the page
  }, [location]);

  return null;  // This component doesn't need to render anything
}

function App() {
  return (
    <Router>
      {/* ScrollToTop Component will ensure the page scrolls to the top on route change */}
      <ScrollToTop />

      <Routes>
        {/* Main Route for Homepage */}
        <Route 
          path="/" 
          element={
            <div className="overflow-x-hidden">
              <Header />
              <Hero />
              <WhatsAppButton phoneNumber={"+16467121079"} />
              <SwitchCalculate />
              <ChooseUs />
              <OurService />
              <Benefit />
              <YourTrun />
              <GigWorker />
              <MakeSwitch />
              <OurClients />
              <ContactUs />
              <Footer />
              <BackToTop />
            </div>
          }
        />
        
        {/* Route for Terms and Conditions */}
        <Route path="/tnc" element={<> <Header /><TNC />    <Footer /></>} />
        <Route path="/privacy" element={<> <Header /><Privacy />    <Footer /></>} />

      </Routes>
    </Router>
  );
}

export default App;
